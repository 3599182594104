import React, { useEffect, useState } from "react";
import { EuiIcon, EuiSideNav, slugify, EuiLoadingChart } from "@elastic/eui";
import RenderPage from "../pages/renderPage";

const HomeComponent = () => {
  const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false);
  const [selectedItemName, setSelectedItem] = useState("Dashboard IN");
  const [isLoading, setIsLoading] = useState(true);
  const toggleOpenOnMobile = () => {
    setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile);
  };

  const selectItem = (name) => {
    setIsLoading(true);
    setTimeout(function () {
      setSelectedItem(name);
      setIsLoading(false);
    }, 1500);
  };

  const createItem = (name, data = {}) => {
    // NOTE: Duplicate `name` values will cause `id` collisions.
    return {
      id: slugify(name),
      name,
      isSelected: selectedItemName === name,
      onClick: () => selectItem(name),
      ...data,
    };
  };

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 2500);
  }, []);
  const sideNav = [
    createItem("Stats", {
      onClick: undefined,
      icon: <EuiIcon type="visualizeApp" />,
      items: [
        createItem("Inbound", {
          onClick: undefined,
          items: [
            createItem("Dashboard IN"),
            // createItem("Advanced KPIs", { disabled: true }),
          ],
        }),
        createItem("Outbound", {
          onClick: undefined,
          items: [
            createItem("Dashboard OUT"),
            // createItem("Advanced KPIs", { disabled: true }),
          ],
        }),
      ],
    }),
    createItem("Call Detail Records", {
      onClick: undefined,
      icon: <EuiIcon type="reportingApp" />,
      items: [createItem("CDR")],
    }),
    createItem("Realtime Monitor", {
      onClick: undefined,
      icon: <EuiIcon type="usersRolesApp" />,
      items: [createItem("Panel")],
    }),
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <EuiSideNav
            aria-label="Complex example"
            mobileTitle="Navigate within $APP_NAME"
            toggleOpenOnMobile={toggleOpenOnMobile}
            isOpenOnMobile={isSideNavOpenOnMobile}
            items={sideNav}
            style={{ width: 200, marginRight: 20 }}
          />
        </div>
        {isLoading ? (
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                marginTop: "18%",

                placeContent: "center",
              }}
            >
              <EuiLoadingChart size="xl" />
            </div>
          </div>
        ) : (
          <RenderPage page={selectedItemName} />
        )}
      </div>
    </>
  );
};

export default HomeComponent;
