import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSelectable,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
  EuiComboBox,
  EuiHorizontalRule,
  EuiButton,
  EuiConfirmModal,
  EuiCallOut,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
} from "@elastic/eui";
import React, { Fragment, useEffect, useState } from "react";
import LoginService from "../../services/login-service";
import AgentsServie from "../../services/realtime-service";
import StatsService from "../../services/stats-service";

const AgentsFlyout = (props) => {
  const [NEWoptions, setNEWOptions] = useState();
  const [NEWselectedOptions, setNEWSelected] = useState();
  const [NEWagentsLoading, setNEWAgentsLoading] = useState(false);
  const [NEWname, setNEWname] = useState();
  const [selectedTabId, setSelectedTabId] = useState("1");
  const [isSelectableLoading, setIsSelectableLoading] = useState(true);
  const [isTagSelected, setIsTagSelected] = useState(false);
  const [isTagSelectedLoading, setIsTagSelectedLoading] = useState(false);
  const [tags, setTags] = useState();

  const [nameTagSelected, setNameTagSelected] = useState();
  const [dataTagSelected, setDataTagSelected] = useState();
  const [tagsPlain, setTagsPlain] = useState();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tabs = [
    {
      id: "1",
      name: "Active groups",
    },
    {
      id: "2",
      name: (
        <>
          <EuiIcon type="plusInCircleFilled" />
          &nbsp; Create new group
        </>
      ),
    },
  ];

  const [isDeleteResponseErrorred, setIsFDeleteResponseErrored] =
    useState(false);
  const [isDeleteResponseSuccess, setIsFDeleteResponseSuccess] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const [agentsLoading, setAgentsLoading] = useState(false);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const renderTabs = tabs.map((tab, index) => (
    <EuiTab
      onClick={() => setSelectedTabId(tab.id)}
      isSelected={tab.id === selectedTabId}
      key={index}
    >
      {tab.name}
    </EuiTab>
  ));

  const fncTagSelected = (newOptions) => {
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setAgentsLoading(true);
    setIsTagSelected(true);
    setIsTagSelectedLoading(true);
    const tagToFound = newOptions.find((e) => e.checked === "on");
    const found = tagsPlain.find((e) => e.id === tagToFound.id);
    setDataTagSelected(found);
    setNameTagSelected(found.name);
    setIsTagSelectedLoading(false);

    const ags = [];

    for (let agent of found.agents) {
      console.log(agent);
      ags.push({
        label: agent.name,
        extension: agent.extension,
        mail: agent.mail,
        teams_id: agent.teams_id,
      });
    }
    setSelected(ags);

    var oldArray = options;

    if (oldArray && oldArray.length >= 1) {
      for (let agentToDelete of found.agents) {
        const index = oldArray.indexOf(agentToDelete.label);
        if (index > -1) {
          // only splice array when item is found
          oldArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }

    setOptions(oldArray);
    setAgentsLoading(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    setNEWAgentsLoading(true);
    // Get Tags
    AgentsServie.get_groups(token)
      .then((response) => {
        const tags = [];
        for (let tag of response.data.message) {
          tags.push({ label: tag.name, id: tag.id, agents: tag.agents });
        }
        setTags(tags);
        setTagsPlain(response.data.message);
        setIsSelectableLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });

    // Get Agents
    AgentsServie.get_agents_for_create_group(token)
      .then((response) => {
        const agents = [];
        for (let agent of response.data.message) {
          agents.push({
            label: agent.name,
            mail: agent.mail,
            extension: agent.extension,
            teams_id: agent.teams_id,
          });
        }
        console.log(agents);
        setOptions(agents);
        setNEWOptions(agents);
        setNEWAgentsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [options, setOptions] = useState();
  const [selectedOptions, setSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setSaveDisabled(false);
  };

  const onNewChange = (selectedOptions) => {
    setNEWSelected(selectedOptions);
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setSaveDisabled(false);
  };

  const onCreateOption = (searchValue, flattenedOptions = []) => {
    if (!searchValue) {
      return;
    }

    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }

    // Select the option.
    setSelected((prevSelected) => [...prevSelected, newOption]);
  };

  const [saveErrorVisible, setSaveErrorVisible] = useState(false);
  const [saveSuccessVisible, setSaveSuccessVisible] = useState(false);
  const [isNEWSuccessVisible, setIsNEWSuccessVisible] = useState(false);
  const [isNEWErroredVisible, setisNEWErroredVisible] = useState(false);

  const clickedOnConfirm = async () => {
    setIsFDeleteResponseErrored(false);
    const token = sessionStorage.getItem("krakenT");
    const tagId = dataTagSelected.id;

    setIsLoading(true);
    AgentsServie.delete_group(token, tagId)
      .then(async (response) => {
        if (response.data.message !== null) {
          setIsFDeleteResponseErrored(false);
          setIsFDeleteResponseSuccess(true);
          setIsLoading(false);
          setIsModalVisible(false);
          setIsSelectableLoading(true);
          setIsTagSelected(false);
          AgentsServie.get_groups(token)
            .then((response) => {
              const tags = [];
              console.log(response.data.response);
              for (let tag of response.data.message) {
                tags.push({ label: tag.name, id: tag.id });
              }
              setIsFDeleteResponseSuccess(false);
              setIsSelectableLoading(false);
              setTags(tags);
              setTagsPlain(response.data.message);
            })

            .catch(() => {
              setIsFDeleteResponseSuccess(false);
              setIsFDeleteResponseErrored(true);
              setIsSelectableLoading(false);
              setIsLoading(false);
            });
        } else {
          setIsFDeleteResponseSuccess(false);
          setIsFDeleteResponseErrored(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsFDeleteResponseErrored(true);
        setIsLoading(false);
      });
  };

  const saveChanges = () => {
    setSaveLoading(true);
    const token = sessionStorage.getItem("krakenT");
    const tagId = dataTagSelected.id;
    const agents = [];
    for (let agent of selectedOptions) {
      agents.push({
        name: agent.label,
        extension: agent.extension,
        mail: agent.mail,
        teams_id: agent.teams_id,
      });
    }
    AgentsServie.update_group(token, tagId, agents)
      .then((response) => {
        setSaveSuccessVisible(true);
        setSaveLoading(false);
        setIsSelectableLoading(true);
        setIsTagSelected(false);
        AgentsServie.get_groups(token)
          .then((response) => {
            const tags = [];
            for (let tag of response.data.message) {
              tags.push({ label: tag.name, id: tag.id });
            }
            setIsFDeleteResponseSuccess(false);
            setIsSelectableLoading(false);
            setTags(tags);
            setTagsPlain(response.data.message);
          })

          .catch(() => {
            setIsFDeleteResponseSuccess(false);
            setIsFDeleteResponseErrored(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setSaveErrorVisible(true);
        setSaveLoading(false);
      });
  };

  const [isNEWSaveLoading, setNEWSaveLoading] = useState(false);

  const clickedOnSaveNewTag = () => {
    setNEWSaveLoading(true);
    const token = sessionStorage.getItem("krakenT");
    const name = NEWname;
    const agents = [];

    for (let agent of NEWselectedOptions) {
      agents.push({
        name: agent.label,
        extension: agent.extension,
        mail: agent.mail,
        teams_id: agent.teams_id,
      });
    }

    console.log(agents);

    AgentsServie.create_new_agent_group(token, name, agents)
      .then((response) => {
        setIsNEWSuccessVisible(true);
        setIsModalVisible(false);
        setIsSelectableLoading(true);
        setIsTagSelected(false);
        AgentsServie.get_groups(token)
          .then((response) => {
            const tags = [];
            for (let tag of response.data.message) {
              tags.push({ label: tag.name, id: tag.id });
            }
            setIsFDeleteResponseSuccess(false);
            setIsSelectableLoading(false);
            setTags(tags);
            setTagsPlain(response.data.message);
            setSelectedTabId("1");
          })

          .catch(() => {
            setIsFDeleteResponseSuccess(false);
            setIsFDeleteResponseErrored(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setisNEWErroredVisible(true);
      });
  };

  return (
    <>
      {" "}
      <EuiFlyout
        ownFocus
        onClose={() => props.closeFlyout()}
        aria-labelledby={"simpleFlyoutTitleId"}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={"Header"}>
              <EuiIcon size={"xl"} type="user" />
              &nbsp; Manage realtime agent groups
            </h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiSpacer size="s" />
          <EuiTabs style={{ marginBottom: "-25px" }}>{renderTabs}</EuiTabs>
        </EuiFlyoutHeader>
        {selectedTabId === "1" && (
          <>
            <EuiFlyoutBody>
              <EuiSelectable
                singleSelection
                isLoading={isSelectableLoading}
                aria-label="Searchable example"
                searchable
                searchProps={{
                  "data-test-subj": "selectableSearchHere",
                  placeholder: "Select one to edit",
                }}
                options={tags}
                onChange={(newOptions) => {
                  fncTagSelected(newOptions);
                }}
              >
                {(list, search) => (
                  <>
                    {search}
                    {list}
                  </>
                )}
              </EuiSelectable>
              {isTagSelected && (
                <>
                  <EuiSpacer size="l" />
                  {isTagSelectedLoading ? (
                    <EuiLoadingSpinner />
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <div>
                          <EuiTitle>
                            <h1>{nameTagSelected}</h1>
                          </EuiTitle>
                        </div>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                          }}
                        >
                          <EuiButton
                            color="danger"
                            iconType="trash"
                            style={{
                              height: 30,
                            }}
                            onClick={() => {
                              showModal();
                            }}
                          >
                            Delete Tag
                          </EuiButton>
                        </div>
                      </div>
                      <EuiSpacer size="s" />
                      <EuiHorizontalRule
                        margin="none"
                        style={{ height: 2, marginRight: 10 }}
                      />
                      <EuiSpacer size="m" />
                      <EuiText size="s">Selected Agents</EuiText>
                      <EuiSpacer size="xs" />
                      <EuiComboBox
                        isLoading={agentsLoading}
                        aria-label="Accessible screen reader label"
                        placeholder="Select or create options"
                        fullWidth
                        options={options}
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        onCreateOption={onCreateOption}
                      />
                    </>
                  )}
                </>
              )}
              <EuiSpacer size="l" />
              <EuiFlexGroup>
                {saveSuccessVisible && (
                  <EuiCallOut size="s" color="success" iconType="user">
                    <p>Changes saved succesfully</p>
                  </EuiCallOut>
                )}
                {saveErrorVisible && (
                  <EuiCallOut size="s" color="danger" iconType="alert">
                    <p>
                      Unable to perform this action, please contact support -
                      soporte@astroline.com
                    </p>
                  </EuiCallOut>
                )}
              </EuiFlexGroup>
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    iconType="cross"
                    onClick={() => props.closeFlyout()}
                    flush="left"
                  >
                    Close
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    isDisabled={saveDisabled}
                    onClick={() => saveChanges()}
                    isLoading={saveLoading}
                    fill
                  >
                    Save changes
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </>
        )}
        {selectedTabId === "2" && (
          <>
            {" "}
            <EuiFlyoutBody>
              <EuiForm component="form">
                <EuiFormRow
                  label="Name"
                  helpText="Type friendly name to the tag"
                >
                  <EuiFieldText
                    required
                    name="first"
                    onChange={(e) => setNEWname(e.target.value)}
                  />
                </EuiFormRow>
                <EuiSpacer size="l" />
                <EuiComboBox
                  isLoading={NEWagentsLoading}
                  placeholder="Select agents"
                  aria-label="Accessible"
                  fullWidth
                  options={NEWoptions}
                  selectedOptions={NEWselectedOptions}
                  onChange={onNewChange}
                  onCreateOption={onCreateOption}
                />{" "}
                <EuiSpacer size="l" />
                {isNEWErroredVisible && (
                  <>
                    <EuiSpacer size="s" />
                    <EuiCallOut size="s" color="danger" iconType="alert">
                      <p>
                        Unable to perform this action, please contact support -
                        soporte@astroline.com
                      </p>
                    </EuiCallOut>
                    <EuiSpacer size="s" />
                  </>
                )}
                {isNEWSuccessVisible && (
                  <>
                    <EuiSpacer size="s" />
                    <EuiCallOut size="s" color="success" iconType="user">
                      <p>Tag saved succesfully</p>
                    </EuiCallOut>
                    <EuiSpacer size="s" />
                  </>
                )}
                <EuiButton
                  onClick={() => {
                    clickedOnSaveNewTag();
                  }}
                >
                  Save
                </EuiButton>
              </EuiForm>
            </EuiFlyoutBody>
          </>
        )}
      </EuiFlyout>
      {isModalVisible && (
        <EuiConfirmModal
          title="Please confirm"
          onCancel={closeModal}
          onConfirm={() => {
            clickedOnConfirm();
          }}
          cancelButtonText="No, don't do it"
          confirmButtonText="Yes, do it"
          defaultFocusedButton="confirm"
          initialFocus="[name=delete]"
          isLoading={isLoading}
        >
          You&rsquo;re about to delete <b>{nameTagSelected}</b>
          <br></br>
          Are you sure you want to do this?
          {isDeleteResponseErrorred ? (
            <>
              <br></br>
              <br></br>
              <EuiCallOut
                size="s"
                title="Sorry, there was an error"
                color="danger"
                iconType="alert"
              >
                <p>
                  Unable to perform this action, please contact support -
                  soporte@astroline.com
                </p>
              </EuiCallOut>
              <br></br>
            </>
          ) : (
            ""
          )}
          {isDeleteResponseSuccess ? (
            <>
              <br></br>
              <br></br>
              <EuiCallOut
                size="s"
                title="Tag deleted successfully"
                color="success"
                iconType="user"
              >
                <p>Tag {nameTagSelected} deleted without errors.</p>
              </EuiCallOut>
              <br></br>
            </>
          ) : (
            ""
          )}
        </EuiConfirmModal>
      )}
    </>
  );
};

export default AgentsFlyout;
