import { Chart, Metric } from "@elastic/charts";
import {
  EuiBasicTable,
  EuiEmptyPrompt,
  EuiLoadingChart,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiStat,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React, { useEffect, useState, useRef } from "react";
import AgentsServie from "../../services/realtime-service";
import "./Queue.css";
import Timer from "react-timer-wrapper";
import Timecode from "react-timecode";

const QueueComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [calls_on_hold, set_calls_on_hold] = useState([
    // { number: "6121244421", time: "00:02" },
    // { number: "6121244421", time: "00:02" },
    // { number: "6121244421", time: "00:02" },
    // { number: "6121244421", time: "00:02" },
  ]);
  const [values_loading, set_values_loading] = useState(true);
  const [queues, set_queues] = useState([]);
  const [queue_width, set_queue_with] = useState("100%");

  const queue_refresher = async (queues_selected) => {
    const token = sessionStorage.getItem("krakenT");
    const queue_names = [];
    for (let queue of queues_selected) {
      queue_names.push(queue.name);
    }

    const new_queues_after_fetch = [];
    AgentsServie.get_queues_status_by_names(token, queue_names)
      .then((response) => {
        for (let queue_status of response.data.message) {
          const found = queues_selected.findIndex(
            (e) => e.name === queue_status.queue.slice(5)
          );
          if (found !== -1) {
            const waiting_calls = [];
            if (queue_status.calls_on_hold_details.length >= 1) {
              for (let waiting_call of queue_status.calls_on_hold_details) {
                const time1 = new Date();
                const time2 = time1.getTime();
                const new_time = time2 - waiting_call.time;
                waiting_calls.push({
                  number: waiting_call.number,
                  time: new_time,
                });
              }
            }

            new_queues_after_fetch.push({
              name: queues_selected[found].name,
              bg_color1: queues_selected[found].bg_color1,
              bg_color2: queues_selected[found].bg_color2,
              bg_color3: queues_selected[found].bg_color3,
              total_calls: queue_status.total_calls,
              attended_calls: queue_status.attended_calls,
              non_attended_calls: queue_status.non_attended_calls,
              hold_time: queue_status.hold_time,
              talk_time: queue_status.talk_time,
              agents_logged: queue_status.agents_logged,
              agents_available: queue_status.agents_available,
              calls_on_hold_quantity: queue_status.calls_on_hold_quantity,
              calls_on_hold_details: waiting_calls,
            });
          }
        }
        set_queues(new_queues_after_fetch);
        set_values_loading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const queueInterval = useRef(null);

  useEffect(() => {
    clearInterval(queueInterval.current);
    queueInterval.current = null;
    set_values_loading(true);
    if (props.queues_to_filter.length < 1) {
      set_queues([]);
      props.enable_queue_list();
      clearInterval(queueInterval.current);
    } else {
      setIsLoading(true);

      const queue_to_set = props.queues_to_filter ? props.queues_to_filter : [];

      const queues_to_push = queue_to_set.slice(0, 3);
      const obj_queues_to_push = [];
      for (let queue of queues_to_push) {
        obj_queues_to_push.push({
          name: queue,
        });
      }

      if (queues_to_push.length === 1) {
        set_queue_with("100%");
        obj_queues_to_push[0].bg_color1 = "#622480";
        obj_queues_to_push[0].bg_color2 = "#762A98";
        obj_queues_to_push[0].bg_color3 = "#8455A8";
      }

      if (queues_to_push.length === 2) {
        set_queue_with("50%");
        obj_queues_to_push[0].bg_color1 = "#622480";
        obj_queues_to_push[0].bg_color2 = "#762A98";
        obj_queues_to_push[0].bg_color3 = "#8455A8";
        obj_queues_to_push[1].bg_color1 = "#38B592";
        obj_queues_to_push[1].bg_color2 = "#3FCEA6";
        obj_queues_to_push[1].bg_color3 = "#7CDEC2";
      }
      if (queues_to_push.length === 3) {
        set_queue_with("40%");
        obj_queues_to_push[0].bg_color1 = "#622480";
        obj_queues_to_push[0].bg_color2 = "#762A98";
        obj_queues_to_push[0].bg_color3 = "#8455A8";
        obj_queues_to_push[1].bg_color1 = "#38B592";
        obj_queues_to_push[1].bg_color2 = "#3FCEA6";
        obj_queues_to_push[1].bg_color3 = "#7CDEC2";
        obj_queues_to_push[2].bg_color1 = "#622480";
        obj_queues_to_push[2].bg_color2 = "#762A98";
        obj_queues_to_push[2].bg_color3 = "#8455A8";
      }
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 2000);
      set_queues(obj_queues_to_push);
      setIsLoading(false);

      clearInterval(queueInterval.current);
      queueInterval.current = null;
      props.enable_queue_list();

      queueInterval.current = setInterval(() => {
        queue_refresher(obj_queues_to_push);
      }, 2000);

      return () => {
        clearInterval(queueInterval.current);
        queueInterval.current = null;
      };
    }
  }, [props.queues_to_filter, props.enable_queue_list()]);

  const columns = [
    {
      field: "number",
      name: "Phone Number",
      sortable: true,
      truncateText: true,
    },
    {
      field: "time",
      name: "Waiting time",
      render: (item) => (
        <EuiText>
          {/* <Timer time={item} active duration={null}> */}
          <Timer active duration={null}>
            <Timecode />
          </Timer>
        </EuiText>
      ),
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "40vh",
        paddingRight: 5,
        paddingTop: 5,
      }}
    >
      {isLoading ? (
        <div className="align_center">
          <EuiLoadingChart size="xl" mono />
        </div>
      ) : (
        <>
          {queues.length >= 1 ? (
            <>
              <div style={{ display: "flex" }}>
                {queues.map((queue) => {
                  return (
                    <div
                      style={{
                        height: "40vh",
                        width: queue_width,
                        paddingRight: 10,
                      }}
                    >
                      <EuiPanel
                        className="align_center"
                        style={{ textAlign: "center", height: "10%" }}
                        paddingSize="s"
                      >
                        <b>{queue.name}</b>
                      </EuiPanel>
                      <EuiSpacer size="xs"></EuiSpacer>
                      <div style={{ height: "40%" }}>
                        <div className="queue_panel">
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color1,
                                      title: "Total calls",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.total_calls
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color1,
                                      title: "Average Handle Time (AHT)",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.talk_time
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color1,
                                      title: "Average Ringing Time",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.hold_time
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color2,
                                      title: "Attended calls",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.attended_calls
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color2,
                                      title: "Non attended calls",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.non_attended_calls
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                          <EuiPanel
                            paddingSize="none"
                            style={{ overflow: "hidden" }}
                          >
                            <Chart>
                              <Metric
                                id="1"
                                data={[
                                  [
                                    {
                                      color: queue.bg_color2,
                                      title: "Connected agents",
                                      value: values_loading ? (
                                        <EuiLoadingSpinner />
                                      ) : (
                                        queue.agents_logged
                                      ),
                                      trendShape: "area",
                                    },
                                  ],
                                ]}
                              />{" "}
                            </Chart>
                          </EuiPanel>
                        </div>
                      </div>
                      <EuiSpacer size="xs"></EuiSpacer>

                      <div style={{ display: "flex", height: "47%" }}>
                        <div style={{ width: "49.5%" }}>
                          <div className="queue_panel_agents">
                            <EuiPanel
                              paddingSize="none"
                              style={{ overflow: "hidden" }}
                            >
                              <Chart size={"s"}>
                                <Metric
                                  id="1"
                                  data={[
                                    [
                                      {
                                        color: queue.bg_color3,
                                        title: "Ongoing calls",
                                        value: values_loading ? (
                                          <EuiLoadingSpinner />
                                        ) : (
                                          queue.calls_on_hold_quantity
                                        ),
                                        trendShape: "area",
                                      },
                                    ],
                                  ]}
                                />{" "}
                              </Chart>
                            </EuiPanel>

                            <EuiPanel
                              paddingSize="none"
                              style={{ overflow: "hidden" }}
                            >
                              <Chart>
                                <Metric
                                  id="1"
                                  data={[
                                    [
                                      {
                                        color: queue.bg_color3,
                                        title: "Available agents",
                                        value: values_loading ? (
                                          <EuiLoadingSpinner />
                                        ) : (
                                          queue.agents_available
                                        ),
                                        trendShape: "area",
                                      },
                                    ],
                                  ]}
                                />{" "}
                              </Chart>
                            </EuiPanel>
                          </div>
                        </div>
                        <div style={{ width: "1%" }}></div>
                        <div style={{ width: "49.5%" }}>
                          <div
                            tabIndex={0}
                            role="region"
                            aria-label=""
                            className="eui-yScrollWithShadows"
                          >
                            <EuiPanel paddingSize="xs">
                              <EuiBasicTable
                                noItemsMessage="No iconming calls"
                                style={{ fontSize: 8 }}
                                tableCaption="Demo for EuiBasicTable with selection"
                                items={
                                  queue.calls_on_hold_details
                                    ? queue.calls_on_hold_details
                                    : []
                                }
                                columns={columns}
                              />
                            </EuiPanel>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <EuiEmptyPrompt
              iconType="faceSad"
              color="warning"
              title={<h2>Please select a queue in order to view data</h2>}
            />
          )}
        </>
      )}
    </div>
  );
};

export default QueueComponent;
