import {
  EuiAvatar,
  EuiCard,
  EuiEmptyPrompt,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPanel,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import AgentsServie from "../../services/realtime-service";
import Timer from "react-timer-wrapper";
import Timecode from "react-timecode";
import { MdPhoneCallback, MdPhoneForwarded } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import "./Agents.scss";

const AgentComponent = (props) => {
  const [agent_group, set_agent_group] = useState();
  const [compact_view, set_compact_view] = useState();
  const [is_errored, set_is_errored] = useState(false);
  const [is_loading, set_is_loading] = useState(true);
  const [teams_token, set_teams_token] = useState();
  const [is_agent_group_selected, set_is_agent_group_selected] =
    useState(false);

  const [agents, set_agents] = useState([]);

  const refresher = async (teams_token, agents) => {
    const token = sessionStorage.getItem("krakenT");
    const ids = [];
    const new_agents_status = [];
    for (let agent of agents) {
      ids.push({ id: agent.teams_id, extension: agent.extension });
    }
    AgentsServie.get_agents_status_by_ids(token, teams_token, ids)
      .then((response) => {
        const agents_to_search = agents;
        for (let agent_status of response.data.message) {
          const findIndex = agents_to_search.find(
            (e) => e.teams_id === agent_status.teams_id
          );
          if (findIndex) {
            new_agents_status.push({
              name: findIndex.name,
              photo: findIndex.photo,
              isDisabled: findIndex.isDisabled,
              teams_id: findIndex.teams_id,
              status: agent_status.status_teams,
              color: agent_status.color_teams,
              extension: agent_status.extension,
              call_status_outbound: agent_status.call_status_outbound,
              call_status_inbound: agent_status.call_status_inbound,
            });
          }
        }
        new_agents_status.sort((a, b) => a.name.localeCompare(b.name));
        set_agents(new_agents_status);
      })

      .catch((error) => {
        console.log(error);
        set_is_errored(true);
        clearInterval(intervalRef.current);
      });
  };
  const intervalRef = useRef(null);

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    set_agents([]);
    set_is_agent_group_selected(true);
    set_is_loading(true);
    set_is_errored(false);
    const found_checked = props.agent_group.find((e) => e.checked === "on");
    if (found_checked) {
      const token = sessionStorage.getItem("krakenT");
      AgentsServie.get_agents_by_group_id(token, found_checked.id)
        .then((response) => {
          const agents = [];
          for (let agent of response.data.message.agents) {
            axios
              .get(
                `https://graph.microsoft.com/v1.0/users/${agent.mail}/photo/$value`,
                {
                  headers: {
                    Authorization: `Bearer ${response.data.message.r_t}`,
                  },
                  responseType: "blob",
                }
              )
              .then(async (o) => {
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(o.data);
                agents.push({
                  name: agent.name,
                  photo: blobUrl,
                  teams_id: agent.teams_id,
                  extension: agent.extension,
                  status: <EuiLoadingSpinner></EuiLoadingSpinner>,
                  color: "#A4B3AE",
                  call_status_outbound: agent.call_status_outbound,
                  call_status_inbound: agent.call_status_inbound,
                });
              })
              .catch(async (e) => {
                agents.push({
                  name: agent.name,
                  photo: "",
                  isDisabled: true,
                  teams_id: agent.teams_id,
                  extension: agent.extension,
                  status: <EuiLoadingSpinner></EuiLoadingSpinner>,
                  color: "#A4B3AE",
                });
              });
          }

          agents.sort((a, b) => a.name.localeCompare(b.name));
          set_agents(agents);
          set_teams_token(response.data.message.r_t);
          intervalRef.current = setInterval(() => {
            refresher(response.data.message.r_t, agents);
          }, 3 * 1000);
          setTimeout(() => {
            set_is_loading(false);
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          set_is_loading(false);
          set_is_errored(true);
        });

      set_compact_view(props.compact_view);
    } else {
      set_is_agent_group_selected(false);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [props.agent_group, props.compact_view]);

  return (
    <div
      tabIndex={0}
      role="region"
      aria-label=""
      className="eui-yScrollWithShadows"
      style={{
        width: "100%",
        //paddingRight: 20,
        paddingTop: 10,
      }}
    >
      {is_agent_group_selected ? (
        <>
          {is_loading ? (
            <EuiLoadingSpinner />
          ) : (
            <>
              {is_errored ? (
                <>
                  <EuiEmptyPrompt
                    iconType="danger"
                    color="danger"
                    title={
                      <h2>
                        Some error ocurred while trying to perform this action,
                        please contact support@astroline.com
                      </h2>
                    }
                  />
                </>
              ) : (
                <>
                  <div
                    className={
                      compact_view
                        ? "agents_panel_compact_view"
                        : "agents_panel"
                    }
                  >
                    {agents.map((agent) => {
                      return (
                        <div>
                          <EuiPanel
                            paddingSize="s"
                            style={{ height: compact_view ? "60px" : "110px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: "60px",
                                marginTop: compact_view ? 0 : 5,
                              }}
                            >
                              <div>
                                <div
                                  class="circle"
                                  style={
                                    agent.call_status_inbound &&
                                    agent.call_status_inbound.status ===
                                      "Ringing"
                                      ? {
                                          marginLeft: compact_view
                                            ? "5px"
                                            : "10px",
                                          backgroundColor: agent.color,
                                          animation: compact_view
                                            ? "call_compact 1.5s ease infinite"
                                            : "call 1.5s ease infinite",
                                          color: "aliceblue",
                                          width: compact_view ? "40px" : "50px",
                                          height: compact_view
                                            ? "40px"
                                            : "50px",
                                          borderRadius: compact_view
                                            ? "40px"
                                            : "50px",
                                          position: "relative",
                                        }
                                      : {
                                          marginLeft: compact_view
                                            ? "5px"
                                            : "10px",
                                          backgroundColor: agent.color,
                                          width: compact_view ? "40px" : "50px",
                                          height: compact_view
                                            ? "40px"
                                            : "50px",
                                          borderRadius: compact_view
                                            ? "40px"
                                            : "50px",
                                          position: "relative",
                                        }
                                  }
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "10%",
                                      top: "10%",
                                    }}
                                  >
                                    <EuiAvatar
                                      size={compact_view ? "m" : "l"}
                                      name={agent.name}
                                      imageUrl={agent.photo}
                                      isDisabled={agent.isDisabled}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginLeft: 10 }}>
                                <div
                                  style={{
                                    height: 40,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                      height: 20,
                                      textOverflow: "clip",
                                    }}
                                  >
                                    <EuiTitle size="xxs">
                                      <h5>{agent.name}</h5>
                                    </EuiTitle>
                                  </div>
                                  <div
                                    style={{
                                      height: 20,
                                    }}
                                  >
                                    {agent.call_status_inbound &&
                                    agent.call_status_inbound.status ===
                                      "Ringing" ? (
                                      <>
                                        <ThreeDots
                                          height="20"
                                          width="20"
                                          radius="9"
                                          color="#762A98"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                      </>
                                    ) : (
                                      agent.status
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                height: "30px",
                                fontSize: 12,
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              {agent.call_status_outbound &&
                              agent.call_status_outbound.status &&
                              !compact_view ? (
                                <>
                                  {agent.call_status_inbound &&
                                  agent.call_status_inbound.status &&
                                  !compact_view ? (
                                    <>
                                      {" "}
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{
                                            width: "35%",
                                            display: "flex",
                                          }}
                                        >
                                          <MdPhoneForwarded /> &nbsp;
                                          {agent.call_status_outbound.status ===
                                          "trying"
                                            ? " Trying..."
                                            : agent.call_status_outbound.status}
                                        </div>
                                        <div
                                          style={{
                                            width: "90%",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: 0,
                                              marginLeft: "auto",
                                              display: "flex",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <div class="vl"></div>
                                            <div style={{ overflow: "hidden" }}>
                                              &nbsp;
                                              {
                                                agent.call_status_outbound
                                                  .destination
                                              }
                                              &nbsp;
                                            </div>
                                            <div class="vl"></div>
                                            &nbsp;
                                            {agent.call_status_outbound
                                              .status === "trying" ? (
                                              <div
                                                style={{
                                                  marginTop: "-2.5px",
                                                }}
                                              >
                                                <ThreeDots
                                                  height="20"
                                                  width="20"
                                                  radius="9"
                                                  color="#762A98"
                                                  ariaLabel="three-dots-loading"
                                                  wrapperStyle={{}}
                                                  wrapperClassName=""
                                                  visible={true}
                                                />
                                              </div>
                                            ) : (
                                              <Timer
                                                time={
                                                  agent.call_status_outbound
                                                    .time
                                                }
                                                active
                                                duration={null}
                                              >
                                                <Timecode />
                                              </Timer>
                                            )}
                                            &nbsp;
                                            <div class="vl"></div>
                                            &nbsp;
                                            <div
                                              style={{ marginTop: "-1.5px" }}
                                            >
                                              <EuiToolTip
                                                position="right"
                                                content={
                                                  <div
                                                    style={{
                                                      fontSize: 11,
                                                    }}
                                                  >
                                                    <b>CallerID number:</b>{" "}
                                                    {
                                                      agent.call_status_outbound
                                                        .callerid_num
                                                    }{" "}
                                                    <br></br>
                                                    <b>
                                                      {" "}
                                                      Destination number:{" "}
                                                    </b>{" "}
                                                    {
                                                      agent.call_status_outbound
                                                        .destination_num
                                                    }{" "}
                                                  </div>
                                                }
                                              >
                                                <EuiIcon
                                                  tabIndex="0"
                                                  type="questionInCircle"
                                                  title="Call Detailed Info"
                                                />
                                              </EuiToolTip>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{
                                            width: "35%",
                                            display: "flex",
                                          }}
                                        >
                                          <MdPhoneCallback /> &nbsp;
                                          {agent.call_status_inbound.status ===
                                          "Ringing"
                                            ? " Ringing..."
                                            : agent.call_status_inbound.status}
                                        </div>
                                        <div
                                          style={{
                                            width: "90%",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: 0,
                                              marginLeft: "auto",
                                              display: "flex",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <div class="vl"></div>
                                            <div style={{ overflow: "hidden" }}>
                                              &nbsp;
                                              {
                                                agent.call_status_inbound
                                                  .callerid_name
                                              }
                                              &nbsp;
                                            </div>
                                            <div class="vl"></div>
                                            &nbsp;
                                            {agent.call_status_inbound
                                              .status === "Ringing" ? (
                                              <div
                                                style={{
                                                  marginTop: "-2.5px",
                                                }}
                                              >
                                                <ThreeDots
                                                  height="20"
                                                  width="20"
                                                  radius="9"
                                                  color="#762A98"
                                                  ariaLabel="three-dots-loading"
                                                  wrapperStyle={{}}
                                                  wrapperClassName=""
                                                  visible={true}
                                                />
                                              </div>
                                            ) : (
                                              <Timer active duration={null}>
                                                <Timecode />
                                              </Timer>
                                            )}
                                            &nbsp;
                                            <div class="vl"></div>
                                            &nbsp;
                                            <div
                                              style={{ marginTop: "-1.5px" }}
                                            >
                                              <EuiToolTip
                                                position="right"
                                                content={
                                                  <div
                                                    style={{
                                                      fontSize: 11,
                                                    }}
                                                  >
                                                    <b>CallerID number:</b>{" "}
                                                    {
                                                      agent.call_status_inbound
                                                        .callerid_num
                                                    }{" "}
                                                    <br></br>
                                                    <b> CallerID name: </b>{" "}
                                                    {
                                                      agent.call_status_inbound
                                                        .callerid_name
                                                    }{" "}
                                                    <br></br>
                                                    <b>
                                                      {" "}
                                                      Destination number:{" "}
                                                    </b>{" "}
                                                    {
                                                      agent.call_status_inbound
                                                        .destination_num
                                                    }{" "}
                                                    <br></br>
                                                    <b>
                                                      Destination name:{" "}
                                                    </b>{" "}
                                                    {
                                                      agent.call_status_inbound
                                                        .destination_name
                                                    }
                                                  </div>
                                                }
                                              >
                                                <EuiIcon
                                                  tabIndex="0"
                                                  type="questionInCircle"
                                                  title="Call Detailed Info"
                                                />
                                              </EuiToolTip>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          width: "35%",
                                          display: "flex",
                                        }}
                                      >
                                        <MdPhoneForwarded /> &nbsp;
                                        {agent.call_status_outbound.status ===
                                        "trying"
                                          ? " Trying..."
                                          : agent.call_status_outbound.status}
                                      </div>
                                      <div
                                        style={{
                                          width: "90%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 0,
                                            marginLeft: "auto",
                                            display: "flex",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div class="vl"></div>
                                          <div style={{ overflow: "hidden" }}>
                                            &nbsp;
                                            {
                                              agent.call_status_outbound
                                                .destination
                                            }
                                            &nbsp;
                                          </div>
                                          <div class="vl"></div>
                                          &nbsp;
                                          {agent.call_status_outbound.status ===
                                          "trying" ? (
                                            <div
                                              style={{ marginTop: "-2.5px" }}
                                            >
                                              <ThreeDots
                                                height="20"
                                                width="20"
                                                radius="9"
                                                color="#762A98"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                              />
                                            </div>
                                          ) : (
                                            <Timer
                                              time={
                                                agent.call_status_outbound.time
                                              }
                                              active
                                              duration={null}
                                            >
                                              <Timecode />
                                            </Timer>
                                          )}
                                          &nbsp;
                                          <div class="vl"></div>
                                          &nbsp;
                                          <div style={{ marginTop: "-1.5px" }}>
                                            <EuiToolTip
                                              position="right"
                                              content={
                                                <div
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  <b>CallerID number:</b>{" "}
                                                  {
                                                    agent.call_status_outbound
                                                      .callerid_num
                                                  }{" "}
                                                  <br></br>
                                                  <b>
                                                    {" "}
                                                    Destination number:{" "}
                                                  </b>{" "}
                                                  {
                                                    agent.call_status_outbound
                                                      .destination_num
                                                  }{" "}
                                                </div>
                                              }
                                            >
                                              <EuiIcon
                                                tabIndex="0"
                                                type="questionInCircle"
                                                title="Call Detailed Info"
                                              />
                                            </EuiToolTip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {agent.call_status_inbound &&
                                  agent.call_status_inbound.status &&
                                  !compact_view ? (
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          width: "35%",
                                          display: "flex",
                                        }}
                                      >
                                        <MdPhoneCallback /> &nbsp;
                                        {agent.call_status_inbound.status ===
                                        "Ringing"
                                          ? " Ringing..."
                                          : agent.call_status_inbound.status}
                                      </div>
                                      <div
                                        style={{
                                          width: "90%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 0,
                                            marginLeft: "auto",
                                            display: "flex",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div class="vl"></div>
                                          <div style={{ overflow: "hidden" }}>
                                            &nbsp;
                                            {
                                              agent.call_status_inbound
                                                .callerid_name
                                            }
                                            &nbsp;
                                          </div>
                                          <div class="vl"></div>
                                          &nbsp;
                                          {agent.call_status_inbound.status ===
                                          "Ringing" ? (
                                            <div
                                              style={{ marginTop: "-2.5px" }}
                                            >
                                              <ThreeDots
                                                height="20"
                                                width="20"
                                                radius="9"
                                                color="#762A98"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                              />
                                            </div>
                                          ) : (
                                            <Timer active duration={null}>
                                              <Timecode />
                                            </Timer>
                                          )}
                                          &nbsp;
                                          <div class="vl"></div>
                                          &nbsp;
                                          <div
                                            style={{
                                              marginTop: "-1.5px",
                                            }}
                                          >
                                            <EuiToolTip
                                              position="right"
                                              content={
                                                <div
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  <b>CallerID number:</b>{" "}
                                                  {
                                                    agent.call_status_inbound
                                                      .callerid_num
                                                  }{" "}
                                                  <br></br>
                                                  <b> CallerID name: </b>{" "}
                                                  {
                                                    agent.call_status_inbound
                                                      .callerid_name
                                                  }{" "}
                                                  <br></br>
                                                  <b>
                                                    {" "}
                                                    Destination number:{" "}
                                                  </b>{" "}
                                                  {
                                                    agent.call_status_inbound
                                                      .destination_num
                                                  }{" "}
                                                  <br></br>
                                                  <b>Destination name: </b>{" "}
                                                  {
                                                    agent.call_status_inbound
                                                      .destination_name
                                                  }
                                                </div>
                                              }
                                            >
                                              <EuiIcon
                                                tabIndex="0"
                                                type="questionInCircle"
                                                title="Call Detailed Info"
                                              />
                                            </EuiToolTip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}{" "}
                            </div>
                          </EuiPanel>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <EuiEmptyPrompt
            iconType="faceSad"
            color="warning"
            title={<h2>Please select a group in order to view agents</h2>}
          />
        </>
      )}
    </div>
  );
};

export default AgentComponent;
