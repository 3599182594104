import {
  EuiButton,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiSelect,
  EuiSelectable,
  EuiSpacer,
  EuiSwitch,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import React, { Fragment, useEffect, useState } from "react";
import AgentsServie from "../../services/realtime-service";
import DashboardOutFlyout from "../DashboardOutFlyout";
import AgentComponent from "./Agents";
import AgentsFlyout from "./Agents_Flyout";
import "./Panel.css";
import QueueComponent from "./Queue";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const PanelComponent = () => {
  const [flyout_visible, set_flyout_visible] = useState(false);
  const [is_popover_agents_open, set_is_popover_agents_open] = useState(false);
  const [is_popover_queues_open, set_is_popover_queues_open] = useState(false);
  const [enable_queue, set_enable_queue] = useState(false);
  const [compact_view, set_compact_view] = useState(false);
  const [queues_to_filter, set_queues_to_filter] = useState([]);
  const [is_groups_list_loading, set_is_groups_list_loading] = useState(true);
  const [is_queue_group_loading, set_is_queue_group_loading] = useState(true);
  const [warning_queues_info, set_warning_queues_info] = useState(false);
  const [agent_group_selected, set_agent_group_selected] = useState([]);

  const [queue_group_selected, set_queue_group_selected] = useState([]);
  const [full_screen_enabled, set_full_screen_enabled] = useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    AgentsServie.get_groups(token)
      .then((response) => {
        const groups = [];
        for (let group of response.data.message) {
          groups.push({
            label: group.name,
            id: group.id,
          });
        }
        set_agent_group_selected(groups);
        set_is_groups_list_loading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    AgentsServie.get_queues(token)
      .then((response) => {
        const queues = [];
        for (let queue of response.data.message.data) {
          queues.push({ label: queue });
        }
        set_queue_group_selected(queues);
        set_is_queue_group_loading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const on_change_agent_selected = (e) => {
    set_agent_group_selected(e);
  };

  const on_change_queue_selected = (e) => {
    const queues_checked = [];
    for (let queue of e) {
      if (queue.checked && queue.checked === "on") {
        queues_checked.push(queue.label);
      }
    }
    if (queues_checked.length > 3) {
      set_warning_queues_info(true);
    } else if (queues_checked.length <= 3) {
      set_warning_queues_info(false);
    }
    set_is_queue_group_loading(true);
    set_queues_to_filter(queues_checked);
    set_queue_group_selected(e);
  };

  const enable_queue_options_list = () => {
    set_is_queue_group_loading(false);
  };
  const enable_queue_stats = () => {
    if (enable_queue) {
      set_enable_queue(false);
    } else {
      set_enable_queue(true);
    }
  };

  const enable_compact_view = () => {
    if (compact_view) {
      set_compact_view(false);
    } else {
      set_compact_view(true);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "93vh",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            <Fragment>
              <EuiFormRow
                display="columnCompressedSwitch"
                label="Select group of agents"
              >
                <EuiPopover
                  panelPaddingSize="none"
                  button={
                    <EuiButton
                      size="xs"
                      iconType="arrowDown"
                      iconSide="right"
                      onClick={() =>
                        set_is_popover_agents_open(!is_popover_agents_open)
                      }
                    >
                      Agents
                    </EuiButton>
                  }
                  isOpen={is_popover_agents_open}
                  closePopover={() => set_is_popover_agents_open(false)}
                >
                  <EuiSelectable
                    isLoading={is_groups_list_loading}
                    searchable
                    singleSelection={true}
                    searchProps={{
                      placeholder: "search group",
                      compressed: true,
                    }}
                    options={agent_group_selected}
                    onChange={(e) => on_change_agent_selected(e)}
                  >
                    {(list, search) => (
                      <div style={{ width: 240 }}>
                        <EuiPopoverTitle paddingSize="s">
                          {search}
                        </EuiPopoverTitle>
                        {list}
                      </div>
                    )}
                  </EuiSelectable>
                  <EuiPopoverFooter paddingSize="s">
                    <EuiButton
                      size="s"
                      fullWidth
                      onClick={() => {
                        set_is_popover_agents_open(false);
                        set_flyout_visible(true);
                      }}
                    >
                      Manage Groups
                    </EuiButton>
                  </EuiPopoverFooter>
                </EuiPopover>
              </EuiFormRow>
            </Fragment>
          </div>
        </div>
        <div className="rotate">
          <EuiHorizontalRule margin="none" style={{ height: 2, width: 30 }} />
        </div>
        <div
          style={{
            marginLeft: 20,
            display: "flex",
          }}
        >
          <div>
            <Fragment>
              <EuiFormRow
                display="columnCompressedSwitch"
                label="Enable queue stats"
              >
                <EuiSwitch
                  showLabel={false}
                  label="Enable queue stats"
                  checked={enable_queue}
                  onChange={enable_queue_stats}
                  compressed
                />
              </EuiFormRow>
            </Fragment>
          </div>
          &nbsp;&nbsp;
          <div>
            {/* <EuiSelect
              id={basicSelectId}
              placeholder="Select some queue"
              options={queue_options}
              value={queue_group_selected}
              onChange={(e) => on_change_queue_selected(e)}
              aria-label="Use aria labels when no actual label is in use"
            /> */}
            {enable_queue && (
              <EuiPopover
                is
                panelPaddingSize="none"
                button={
                  <EuiButton
                    size="xs"
                    iconType="arrowDown"
                    iconSide="right"
                    onClick={() =>
                      set_is_popover_queues_open(!is_popover_queues_open)
                    }
                  >
                    Queues
                  </EuiButton>
                }
                isOpen={is_popover_queues_open}
                closePopover={() => set_is_popover_queues_open(false)}
              >
                <EuiSelectable
                  isLoading={is_queue_group_loading}
                  searchable
                  singleSelection={false}
                  searchProps={{
                    placeholder: "search queue",
                    compressed: true,
                  }}
                  options={queue_group_selected}
                  onChange={(e) => on_change_queue_selected(e)}
                >
                  {(list, search) => (
                    <div style={{ width: 240 }}>
                      <EuiPopoverTitle paddingSize="s">
                        {search}
                      </EuiPopoverTitle>
                      {list}
                    </div>
                  )}
                </EuiSelectable>
              </EuiPopover>
            )}
          </div>
          {warning_queues_info && (
            <>
              &nbsp;
              <EuiToolTip
                content={
                  "Remember that no more than 3 queues can be displayed in this view."
                }
              >
                <EuiIcon tabIndex="0" type="error" title="error" />
              </EuiToolTip>
            </>
          )}
        </div>
        <div
          style={{
            marginRight: 0,
            marginLeft: "auto",
            display: "flex",
          }}
        >
          <Fragment>
            <EuiFormRow
              display="columnCompressedSwitch"
              label="Enable compact agent view"
            >
              <EuiSwitch
                showLabel={false}
                label="Enable queue stats"
                checked={compact_view}
                onChange={enable_compact_view}
                compressed
              />
            </EuiFormRow>
            <div className="rotate" style={{ marginLeft: -15 }}>
              <EuiHorizontalRule
                margin="none"
                style={{ height: 2, width: 30 }}
              />
            </div>
            &nbsp; &nbsp;
            <div>
              <EuiButton
                isDisabled={
                  agent_group_selected || queues_to_filter.length >= 1
                    ? false
                    : true
                }
                size="xs"
                iconType={"fullScreen"}
                onClick={handle.enter}
              >
                {" "}
                TV MODE
              </EuiButton>
            </div>
            &nbsp;
          </Fragment>
        </div>
      </div>
      <EuiHorizontalRule
        margin="none"
        style={{ height: 2, marginRight: 10, marginTop: 4 }}
      />

      {agent_group_selected && (
        <>
          <FullScreen handle={handle}>
            {enable_queue && queues_to_filter && (
              <>
                <div style={{ width: "100%", height: "39vh" }}>
                  <QueueComponent
                    queues_to_filter={queues_to_filter}
                    enable_queue_list={enable_queue_options_list}
                  />
                </div>
                <div style={{ height: "3vh" }}></div>
              </>
            )}
            <>
              <div
                style={{
                  height: enable_queue && queues_to_filter ? "48vh" : "100vh",
                }}
              >
                <AgentComponent
                  agent_group={agent_group_selected}
                  compact_view={compact_view}
                />
              </div>
            </>
          </FullScreen>
        </>
      )}
      {flyout_visible ? (
        <AgentsFlyout closeFlyout={() => set_flyout_visible(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default PanelComponent;
