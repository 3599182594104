import http from "../http-common";

const get_agents_for_create_group = (token) => {
  return http.get(`/api/v1/realtime/agents_by_company_id`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const create_new_agent_group = (token, name, agents) => {
  return http.post(
    `/api/v1/realtime/create_new_group`,
    {
      name: name,
      agents: agents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const get_groups = (token) => {
  return http.get(`/api/v1/realtime/get_groups`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const get_queues = (token) => {
  return http.get(`/api/v1/realtime/get_queues`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const get_agents_by_group_id = (token, id) => {
  return http.get(`/api/v1/realtime/agents_by_group_id?group_id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const get_queues_status_by_names = (token, queues) => {
  return http.post(
    `/api/v1/realtime/get_queues_status`,
    {
      queues,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const get_agents_status_by_ids = (token, token_teams, agents) => {
  return http.post(
    `/api/v1/realtime/get_agents_status_by_ids?token=${token_teams}`,
    {
      agents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const delete_group = (token, group_id) => {
  return http.delete(`/api/v1/realtime/delete_group?group_id=${group_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const update_group = (token, group_id, agents) => {
  return http.put(
    `/api/v1/realtime/update_group?group_id=${group_id}`,
    {
      agents: agents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const AgentsServie = {
  get_agents_for_create_group,
  create_new_agent_group,
  get_groups,
  delete_group,
  update_group,
  get_agents_by_group_id,
  get_agents_status_by_ids,
  get_queues,
  get_queues_status_by_names,
};

export default AgentsServie;
